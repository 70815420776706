import { type FetchError } from 'ofetch';
import type { Certificate } from '~/models/Certificate';
import { CertificateFactory } from '~/models/factories/CertificateFactory';
import BaseApiService from '~/services/api/BaseApiService';
import type { CertificateResponse } from '~/types/Certificate';
import type {
    ReadSubscriptionRegistrationPointsDto,
    SubscriptionPeriodResponse,
    SubscriptionRegistrationPointsResponse,
    WriteSubscriptionDto,
} from '~/types/Subscription';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';

export default class SubscriptionService extends BaseApiService {
    public static basePath = 'subscriptions';

    async fetchSubscription(subscriptionId: number) {
        try {
            const response = await this.baseGet<SubscriptionPeriodResponse>(
                `${this.createPath(SubscriptionService.basePath)}/${subscriptionId}`,
            );

            const data = (new CertificateFactory()).toModel(response.data);

            return data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async fetchPointsById(subscriptionId: number): Promise<ReadSubscriptionRegistrationPointsDto[]> {
        try {
            const response = await this.baseGet<SubscriptionRegistrationPointsResponse>(
                `${this.createPath(SubscriptionService.basePath)}/${subscriptionId}/points`,
            );

            return response.data;
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    async create(data: WriteSubscriptionDto): Promise<Certificate> {
        try {
            const response = await this.basePost<CertificateResponse>(
                this.createPath(SubscriptionService.basePath),
                data,
            );

            return (new CertificateFactory()).toModel(response.data);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    update(
        certificateId: number,
        data: Pick<WriteSubscriptionDto, 'certificateState' | 'registrationRoute'>,
    ): Promise<null> {
        try {
            return this.basePatch<null>(
                `${this.createPath(SubscriptionService.basePath)}/${certificateId}`,
                data,
            );
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }

    delete(certificateId: number): Promise<null> {
        try {
            return this.baseDelete(`${this.createPath(SubscriptionService.basePath)}/${certificateId}`);
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
